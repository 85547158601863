/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger à Madrid"), React.createElement("p", null, "Pour se loger, Madrid s’articule en plusieurs quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/landmark/es/puerta-del-sol.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Sol"), " : le centre de Madrid, de la Puerta del Sol à l’Opéra."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/es/madrid/malasana.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Malasaña"), ", au nord : berceau de la Movida et quartier branché de Madrid."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/es/madrid/chueca.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Chueca"), ", au nord-est : quartier LGBT de Madrid très prisé des noctambules."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/es/madrid/04salamanca.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Salamanca"), ", à l’est de Chueca : très chic, avec ses restaurants étoilés et ses enseignes de luxe."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/es/madrid/barriodelasletras.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Las Letras"), ", au sud-est : autour de la calle de las Huertas, près du musée du Prado et du parc du Retiro."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/es/madrid/lavapies.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Lavapiés"), ", au sud : quartier populaire, connu pour ses restaurants et ses petits concerts."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/es/madrid/lalatina.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "La Latina"), ", au sud-ouest : célèbre pour les bars à tapas de la cava Baja et le marché aux puces El Rastro."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
